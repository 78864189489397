<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-text">
        <b-form @submit="saveData" @reset="onReset">
          <b-form-group id="input-group-2" label="Your Name:" label-for="input-2">
            <b-form-input
              v-model="form.name"
              required
              placeholder="Enter name"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
            description="We'll never share your email with anyone else."
          >
            <b-form-input
              v-model="form.email"
              type="email"
              required
              placeholder="Enter email"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-5" label="Your Birth:" label-for="input-2">
            <b-form-input
              v-model="form.date_birth"
              required
              type="date"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-3" label="Education:" label-for="input-3">
            <b-form-select
              id="input-3"
              v-model="form.education"
              :options="education"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group id="input-group-6" label="Your Addres:" label-for="input-2">
            <b-form-input
              v-model="form.address"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-6" label="Your Password:" label-for="input-2">
            <b-form-input
              v-model="form.password"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-form>
      </div>
    </b-alert>
  </div>
</template>

<script>
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'
import { Fetch_MEducation} from "@/core/services/store/mEducation.module";
export default {
  data() {
    return {
      form: {
        email: '',
        name: '',
        date_birth: '',
        password: '',
        education: null,
        address: '',
        checked: []
      },
      education: [],
      agendaid: ''
    }
  },
  created() {
    this.$store.dispatch(Fetch_MEducation)
      .then(() => {
        var education = this.$store.state.mEducation.master_Education;
        this.education = education.map(item => ({
          text: item.EducationName,
          value: item.EducationID
        }));
      })
      .catch(error => {
        console.error('Error fetching education data:', error);
      });
  },

  methods: {
    onReset() {
      this.form.email = ''
      this.form.name = ''
      this.form.date_birth = ''
      this.form.address = ''
      this.form.education = null
      this.form.checked = []
    },

    saveData() {
    const formData = new FormData();
    formData.append("name", this.form.name);
    formData.append("date_of_birth", this.form.date_birth);
    formData.append("email", this.form.email);
    formData.append("address", this.form.address);
    formData.append("agenda_test_id", this.agendaid);
    formData.append("education_id", this.form.education);
    formData.append("password", this.form.password);
    let contentType = `application/form-data`;

    Services.PostData(
      ApiService,
      `process/participant/`,
      formData,
      contentType,
      response => {
        if (response.status) {
          Swal.fire({
            title: "",
            html: "Data saved successfully. <br> Simpan link ini jika ada kendala yang akhirnya mengharuskan Anda harus login kembali. <br>http://localhost:8080/#/logintest",
            icon: "success",
            heightAuto: true,
            timer: 180000
          });
          this.onReset();

          // Redirect to the login page
          this.$router.push({ path: '/logintest', replace: true });
        } else {
          Swal.fire({
            title: "",
            text: response.data.error,
            icon: "info",
            heightAuto: true,
            timer: 1500
          });
        }
      },
      error => {
        console.error('Error during API call:', error);
      }
    )
  }

  },
  mounted(){
    var education = this.$store.state.mEducation.master_Education;
    this.education = education.map(item => ({
      text: item.EducationName,
      value: item.EducationID
    }));
    this.agendaid = this.$route.params.agendaid;
  }
}
</script>
